import React, { Component, useEffect, useState } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    NavLink
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import avatar5 from '../../Images/avatar5.png';
import logo from '../../Images/logo.png';

const getClassName = ({ isActive }) => {
    return isActive ? "nav-link active" : "nav-link";
}

const SideBar = () => {
    let { userInfo } = useSelector(state => state.auth);
const isMFI = localStorage.getItem('selectedCompany');


    if (!userInfo) {
        userInfo = {};
    }

    return (
        <aside className="main-sidebar elevation-4 sidebar-dark-navy">
            {/* Brand Logo */}
            <a href="#" className="brand-link" >
                <img src={logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8', background: 'white', padding: 2 }} />
                <span className="brand-text font-weight-light"><strong style={{ color: 'orange', fontWeight: 700 }}>MES</strong> Modena</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src={avatar5} className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{userInfo.fullname}</a>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
                        {/* <li className="nav-item ">
                            <NavLink to="/language" className={getClassName}>
                                <i className="nav-icon fas fa-globe" />
                                <p>Manage Language</p>
                            </NavLink>
                        </li> */}
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-tags" />
                                <p>
                                    Quality
                                    <i className="right fas fa-angle-left"></i>
                                </p>

                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink to="/incoming" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>In-Coming</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/inprocess" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>{isMFI === 'MFI' ? 'Out-Going' : 'In-Process'}</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/quality-issue" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Quality Issue</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                        <NavLink to="/planprod" className={getClassName}>
                                        <i className="nav-icon fas fa-box" />
                                        <p>Planning Production</p>
                                    </NavLink>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-box"></i>
                                <p>
                                    Production
                                    <i className="right fas fa-angle-left"></i>
                                </p>

                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink to="/lsbc" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>LSBC</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-cubes" />
                                <p>
                                    Production
                                    <i className="right fas fa-angle-left"></i>
                                </p>

                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink to="/brand" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Assembly</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/category" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Repair</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-tools" />
                                <p>
                                    Configurations
                                    <i className="right fas fa-angle-left"></i>
                                </p>

                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink to="/metavalue" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Meta Value</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/category" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Category</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/insp-category" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection Category</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/insp-item" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection Items</p>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to="/insp-aql" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection AQL</p>
                                    </NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="/insp-report" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection Reports</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/insp-ffr" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection FFR</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/insp-sop" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Inspection SOP</p>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/mast-sta" className={getClassName}>
                                        <i className="far fa-circle nav-icon" />
                                        <p>Master Standard Time</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-header">General Menu</li>
                        <li className="nav-item">
                            <NavLink to="/roleaccess" className={getClassName}>
                                <i className="nav-icon fas fa-address-card" />
                                <p>Roles</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/user" className={getClassName}>
                                <i className="nav-icon fas fa-user-tag" />
                                <p>Users</p>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* /.sidebar */}
        </aside>


    )

}

export default SideBar;
