import React, { Component, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import MTable from '../../Components/MTable/MTable';
import Switch from '@mui/material/Switch';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { getItemByPo, getPoAll } from '../../Service/SapService';
import { changeIqcStatus, getIqcById } from '../../Service/IqcService';
import { getIqcPartByIqc } from '../../Service/IqcPartService';
import { getInspFfrAll } from '../../Service/InspFfrService';
import { getQualityIssueAll } from '../../Service/QualityIssueService';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import PageOverlay from '../../Components/Overlay/PageOverlay';
import { TabView, TabPanel } from 'primereact/tabview';
const { $ } = window;
const localState = {};

let timeout = 0;

const IncomingDetailScreen = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    let { userInfo } = useSelector(state => state.auth);
    const [show, setShow] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [comment, setComment] = useState("");
    const [action, setAction] = useState(1);
    const isMFI = localStorage.getItem('selectedCompany');
    const [ iqcData, setIqcData ] = useState({
        id_aql: 0,
        docnumber: "",
        inspdate: "",
        title: "",
        ponumber: "",
        grnumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        lotorder: "",
        lotqty: 0,
        serialno: "",
        assyproc: "",
        insprule: "",
        inspqty: 0,
        objtype: 0,
        docentry: 0,
        linenum: 0,
        whfrom: "",
        whdest: "",
        is_changed: false,
        changenote: "",
        conclusion: "",
        inspstatus: 0,
        incident_id: "",
        incident_status: ""
    });
    const [ qualityIssue, setQualityIssue ] = useState({
        issue: "-",
        lot_order: "-",
        defect_qty: "-",
        ffr_status: "-",
        defect_rate: "-"
    });

    const [comparation, setComparation] = useState([]);
    const [specification, setSpecification] = useState([]);
    const isCompany = localStorage.getItem('selectedCompany');
    const onGoback = () => {
        navigate(-1);
    }

    const judgementTemplate = (options) => {
        return <span styles={{textAlign: 'center'}}>{options.judgement == 'OK' ? `OK` : `NG`}</span>;
    }

    const imageOldImageTemplate = (options) => {
        return <img src={options.old_image} width={`150px`} height={`150px`} />
    }

    const imageNewImageTemplate = (options) => {
        return <img src={options.new_image} width={`150px`} height={`150px`} />
    }

    const imageOldImageTemplateSpec = (options) => {
        return (<>
            <tr>
                <td>
                    <img src={options.old_image} width={`150px`} height={`150px`} />
                </td>
            </tr>
            <div style={{ height: 1, background: '#ddd', margin: '10px 0' }} />
            <tr>
                <td>
                    <span>Spec: {options.old_image_spec}</span>
                </td>
            </tr>
        </>);
    }

    const imageNewImageTemplateSpec = (options) => {
        return (<>
            <tr>
                <td>
                    <img src={options.new_image} width={`150px`} height={`150px`} />
                </td>
            </tr>
            <div style={{ height: 1, background: '#ddd', margin: '10px 0' }} />
            <tr>
                <td>
                    <span>Spec: {options.new_image_spec}</span>
                </td>
            </tr>
        </>);
    }

    const headerTemplate = (data) => {
        return (
            <div style={{display: 'flex', background: '#f8f9fa'}}>
                <h6 style={{paddingLeft: '8px', marginTop: '8px'}}>{data.inspcategname}</h6>
            </div>
        );
    }

    let headerColumnGroup = <ColumnGroup>
        <Row>
            <Column field="no" header="No" alignHeader="center" style={{ width: '5%' }} rowSpan={2}/>
            <Column field="name" header="Item Pemeriksaan" alignHeader="center" rowSpan={2}/>
            <Column field="specification" header="Spesifikasi" alignHeader="center" rowSpan={2}/>
            <Column field="standard" header="Standard" alignHeader="center" rowSpan={2}/>
            <Column header="Hasil" alignHeader="center" colSpan={2} />
        </Row>
        <Row>
            <Column header="Aktual" align="center" alignHeader="center" field="aktual_standard" style={{ width: '20%' }}/>
            <Column header="Judge" align="center" alignHeader="center" field="judgement" style={{ width: '10%', minWidth: '85px' }}/>
        </Row>
    </ColumnGroup>;

    let headerColumnGroupPictureComp = <ColumnGroup>
        <Row>
            <Column field="part_name" header="Label" alignHeader="center" />
            <Column header="Gambar Lot Baru" alignHeader="center" />
            <Column field="description" header="Keterangan" alignHeader="center" />
        </Row>
        {/* <Row>
            <Column header="Lot Lama" align="center" alignHeader="center" style={{ width: '22%' }}/>
            <Column header="Lot Baru" align="center" alignHeader="center" style={{ width: '22%' }}/>
        </Row> */}
    </ColumnGroup>;

    let headerColumnGroupPictureSpec = <ColumnGroup>
    <Row>
        <Column field="part_name" header="Nama Part" alignHeader="center" rowSpan={2}/>
        <Column header="Gambar" alignHeader="center" colSpan={2} />
        <Column field="part_sub_name" header="Subtitusi" alignHeader="center" rowSpan={2}/>
        <Column field="description" header="Keterangan" alignHeader="center" rowSpan={2}/>
    </Row>
    <Row>
        <Column header="Lot Lama" align="center" alignHeader="center" style={{ width: '22%' }}/>
        <Column header="Lot Baru" align="center" alignHeader="center" style={{ width: '22%' }}/>
    </Row>
    </ColumnGroup>;

    const getOldPartData = async (id) => {
        getIqcPartByIqc(id).then(res => {
            let compare = [];
            let spec = [];

            if(res.data.length != 0){
                res.data.map((item) => {
                    if(item.part_type.includes('comparation')){
                        item.type = "old";
                        let countOld = 0;
                        let countNew = 0;
                        item.images.map((image) => {
                            if(image.file_type.includes('old') && !countOld){
                                item.old_image_id = image.id;
                                item.old_image_name = image.file_name;
                                item.old_image = image.file_url;
                                countOld = 1;
                            }else if(image.file_type.includes('new') && !countNew){
                                item.new_image_id = image.id;
                                item.new_image_name = image.file_name;
                                item.new_image = image.file_url;
                                countNew = 1;
                            }
                        });
                        
                        compare.push(item);
                    }else{
                        item.type = "old";
                        let countOld = 0;
                        let countNew = 0;
                        item.images.map((image) => {
                            if(image.file_type.includes('old') && !countOld){
                                item.old_image_id = image.id;
                                item.old_image_name = image.file_name;
                                item.old_image = image.file_url;
                                item.old_image_spec = image.media_desc;
                                countOld = 1;
                            }else if(image.file_type.includes('new') && !countNew){
                                item.new_image_id = image.id;
                                item.new_image_name = image.file_name;
                                item.new_image = image.file_url;
                                item.new_image_spec = image.media_desc;
                                countNew = 1;
                            }
                        });
                        
                        spec.push(item);
                    }
                });
            }

            setComparation(compare);
            setSpecification(spec);
        });
    }
    
    function getCurrentDateFormatted() {
        const currentDate = new Date();
        const year = currentDate.getFullYear(); // Gets the year (4 digits)
        const month = currentDate.getMonth() + 1; // Gets the month (0-11, thus +1 to adjust)
        const day = currentDate.getDate(); // Gets the day of the month (1-31)
    
        // Pad the month and day with a leading zero if they are less than 10
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDay = day < 10 ? '0' + day : day;
    
        // Construct the formatted date string in 'YYYY-MM-DD' format
        return `${year}-${formattedMonth}-${formattedDay}`;
    }

 

    const onChangeStatus = (e, id, docnumber, incident_id) => {
        e.preventDefault();
        setShow(false);
        const qty = iqcData.transfer;
        const inspRule = iqcData.insprule;
        console.log('ini inspRule', inspRule);
        const sumQty = qty.reduce((accumulator, item) => {
            return accumulator + item.qty;
        }, 0);

        const assytype = iqcData.assyproc;
        const companycode = iqcData.company_code;

        if (companycode !== isCompany) {
            Swal.fire({
                icon: 'error',
                title: 'Process Cancelled',
                text: 'Please refresh page and reselect company MI or MFI!',
                confirmButtonText: 'OK'
            });
            return;
        }
        Swal.fire({
            title: action == 1 ? 'Are you sure want to approve data ?' : 'Are you sure want to revise data ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setProcessing(true); 
                if (assytype == 'CBU' && companycode == 'MI' && inspRule == 'S') {
                    const body = {
                            "incident_id": incident_id,
                            "action": action,
                            "name": userInfo && userInfo.fullname,
                            "nik" : userInfo && userInfo.nik,
                            "url" : window.location.href,
                            "docnumber": docnumber,
                            "comment": comment,
                            "planproduction" : [
                                {
                                    "ponumber": iqcData.ponumber,
                                    "tr_iqcheader_id": id,
                                    "itemcode": iqcData.itemcode,
                                    "itemname": iqcData.itemname,
                                    "itemdesc": iqcData.itemdesc,
                                    "lotorder": iqcData.lotorder,
                                    "assytype": iqcData.assyproc,
                                    "statusqc" : iqcData.transfer[0].description,
                                    "destinationwhs" : iqcData.transfer[0].whdest,
                                    "companycode": iqcData.company_code,
                                    "prodqty": iqcData.transfer[0].qty,
                                    "created_date": getCurrentDateFormatted(),
                                    "updated_date": getCurrentDateFormatted(),
                                },
                                {
                                    "ponumber": iqcData.ponumber,
                                    "tr_iqcheader_id": id,
                                    "itemcode": iqcData.itemcode,
                                    "itemname": iqcData.itemname,
                                    "itemdesc": iqcData.itemdesc,
                                    "lotorder": iqcData.lotorder,
                                    "assytype": iqcData.assyproc,
                                    "statusqc" : iqcData.transfer[1].description,
                                    "destinationwhs" : iqcData.transfer[1].whdest,
                                    "companycode": iqcData.company_code,
                                    "prodqty": iqcData.transfer[1].qty,
                                    "created_date": getCurrentDateFormatted(),
                                    "updated_date": getCurrentDateFormatted(),
                                }
                            ]
                    }
                    changeIqcStatus(id, body).then(res => {
                        setProcessing(false);
                        if (res.status == 200 || res.status == 201) {
                            Swal.fire({
                                icon: 'success',
                                title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                                text: 'Data has been changed!'
                            });
                            
                            navigate("/incoming");
                        }
                    }).catch(({response: { data } }) => {
                        setProcessing(false);
                        Swal.fire({
                            icon: 'error',
                            title: 'Approval action fail',
                            text: 'Data can not be changed! (' + data.message + ')'
                        });
                    });
                } else {
                    const body = {
                        "incident_id": incident_id,
                        "action": action,
                        "name": userInfo && userInfo.fullname,
                        "nik" : userInfo && userInfo.nik,
                        "url" : window.location.href,
                        "docnumber": docnumber,
                        "comment": comment,
                        "ponumber": iqcData.ponumber,
                        "tr_iqcheader_id": id,
                        "itemcode": isCompany == 'MI' ? iqcData.itemcode : iqcData.itemcode_assembly,
                        "itemname": iqcData.itemname,
                        "itemdesc": iqcData.itemdesc,
                        "lotorder": iqcData.lotorder,
                        "assytype": iqcData.assyproc,
                        "statusqc" : iqcData.transfer[0].description,
                        "destinationwhs" : iqcData.transfer[0].whdest,
                        "companycode": iqcData.company_code,
                        "prodqty": sumQty,
                        "created_date": getCurrentDateFormatted(),
                        "updated_date": getCurrentDateFormatted(),
                    };

                    changeIqcStatus(id, body).then(res => {
                        setProcessing(false);
                        if (res.status == 200 || res.status == 201) {
                            Swal.fire({
                                icon: 'success',
                                title: action == 1 ? 'Approve data success !' : 'Reject data success !',
                                text: 'Data has been changed!'
                            });
                            
                            navigate("/incoming");
                        }
                    }).catch(({response: { data } }) => {
                        setProcessing(false);
                        Swal.fire({
                            icon: 'error',
                            title: 'Approval action fail',
                            text: 'Data can not be changed! (' + data.message + ')'
                        });
                    });
                }
                
            }
        });
    }

    const renderFooter = () => {
        return (
            <div>
                <button 
                    type='button' className='btn btn-danger' title='Cancel'
                    style={{ whiteSpace: 'nowrap' }} 
                    onClick={(e) => setShow(false)}>
                        <i className='fa fa-ban' /> Cancel
                </button>
                <button 
                    type='button' className='btn btn-primary' title='Submit'
                    style={{ marginRight: 10, whiteSpace: 'nowrap' }} 
                    onClick={(e) => onChangeStatus(e, iqcData.id, iqcData.docnumber, iqcData.incident_id)}>
                        <i className='fa fa-check' /> Submit
                </button>
            </div>
        );
    }
    
    useEffect(() => {
        setProcessing(true);
        getIqcById(id).then(res => {
            setIqcData(res.data);

            if(res.data.ffr_id != null && res.data.ffr_id != ""){
                getInspFfrAll({'filter': 'id:'+res.data.ffr_id}).then(res => {
                    if(res.data.data.length > 0){
                        setQualityIssue({...qualityIssue, 
                            ffr_status: res.data.data[0].ffr_rate * 100 + " %"
                        });
                    } else {
                        setQualityIssue({...qualityIssue, 
                            ffr_status: "-"
                        });
                    }
                });
            }

            if(res.data.quality_issue_id != null && res.data.quality_issue_id != ""){
                getQualityIssueAll({'filter': 'id:'+res.data.quality_issue_id}).then(res => {
                    if(res.data.data.length > 0){
                        setQualityIssue({...qualityIssue, 
                            issue: res.data.data[0].issue,
                            lot_order: res.data.data[0].lotorder,
                            defect_qty: res.data.data[0].defqty,
                            defect_rate: res.data.data[0].defrate
                        });
                    } else {
                        setQualityIssue({...qualityIssue, 
                            issue: "-",
                            lot_order: "-",
                            defect_qty: "-",
                            defect_rate: "-"
                        });
                    }
                });
            }

            getOldPartData(id).then(res => {
                setProcessing(false);
            });
        });
    }, []);

    let groupedData = {};
    let newData = [];
    if (iqcData && iqcData.data) {
        newData = iqcData.data;
        console.log('ini new data', newData);
    
        // Ensure newData is an array
        if (Array.isArray(newData)) {
            groupedData = groupDataByPartName(newData);
            console.log('ini groupedData before', groupedData);
        } else {
            console.error('newData is not an array');
        }
    } else {
        console.error('iqcData or iqcData.data is undefined');
    }

    function groupDataByPartName(newData) {
        const groupedData = {};
        newData.forEach(item => {
            const categname = item.part_name;
            if (!groupedData[categname]) {
                groupedData[categname] = [];
            }
            groupedData[categname].push(item);
        });
        return groupedData;
    }
    
    const aktualTemplate = (options) => {
        console.log('ini options view', options)
        console.log('ini new data dalam aktualtemplate', newData)
        // if (isMFI == 'MI') {
        //     return <input onChange={(e) => {
        //         newData.filter(insp => insp.id == options.id).map((insp) => {
        //             insp.aktual = e.target.value;
        //         })}} className='form-control' defaultValue={options.aktual} />
        // } else {
        //     return <input onChange={(e) => {
        //         inspReport.filter(insp => insp.id == options.id).map((insp) => {
        //             insp.aktual = e.target.value;
        //         })}} className='form-control' defaultValue={options.specification} />
        // }
    }
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <PageOverlay display={processing} />
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title w-100'>
                                <i className='fa fa-tools' /> View IQC
                                <div className='d-flex float-right'>
                                    { (iqcData.incident_status != 'COMPLETED') &&
                                        <>
                                            { (iqcData.incident_status == null && iqcData.incident_id == null) &&
                                                <>
                                                    <button 
                                                        type='button' className='btn btn-primary' 
                                                        style={{ width: '100%', marginLeft: 20, whiteSpace: 'nowrap'}} 
                                                        onClick={(e) => {setAction(1); setShow(true);}}>
                                                            <i className='fa fa-check' /> Review Inspector
                                                    </button>
                                                    {/* <button 
                                                        type='button' className='btn btn-danger' 
                                                        style={{ width: '100%', marginLeft: 10, whiteSpace: 'nowrap'}} 
                                                        onClick={(e) => {setAction(2); setShow(true);}}>
                                                            <i className='fa fa-times' /> Revise
                                                    </button> */}
                                                </>
                                            }
                                            { ((iqcData.incident_status == 'ONPROGRESS' || iqcData.incident_status == 'ONREVISED') && iqcData.next_approval_nik == userInfo.nik) &&
                                                <>
                                                    <button 
                                                        type='button' className='btn btn-primary' 
                                                        style={{ width: '100%', marginLeft: 20, whiteSpace: 'nowrap'}} 
                                                        onClick={(e) => {setAction(1); setShow(true);}}>
                                                            <i className='fa fa-check' />{` Review ` + iqcData.next_approval_step}
                                                    </button>
                                                    { iqcData.incident_status == 'ONPROGRESS' &&
                                                        <button 
                                                            type='button' className='btn btn-danger' 
                                                            style={{ width: '100%', marginLeft: 10, whiteSpace: 'nowrap'}} 
                                                            onClick={(e) => {setAction(2); setShow(true);}}>
                                                                <i className='fa fa-times' /> Revise
                                                        </button>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>KBT No</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.docnumber}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>PO No</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.ponumber}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Model</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.itemname}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Serial</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.serialno}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Assy. Process</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.assyproc}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Tanggal Tiba</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.arrivaldate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Lot Order</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.lotorder}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Lot Qty</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.lotqty}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>WH Prod</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.whfrom}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>WH FG</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.whdest}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Perubahan</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.is_changed == 0 ? `Ada` : (iqcData.is_changed == 1 ? `Tidak` : `Baru`)}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='form-group'>
                                                <label>Tanggal Uji</label>
                                            </div>
                                        </div>
                                        <div className='col-md-9'>
                                            <span>: {iqcData.inspdate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor='lot-order'>Informasi</label>
                                        <div>
                                            <span>{iqcData.changenote}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor='lot-qty'>Kesimpulan</label>
                                        <div>
                                            <span>{iqcData.conclusion}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> List Laporan</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        {isMFI == 'MI' && (
                                            <div>
                                                <DataTable value={iqcData.data} size="small" responsiveLayout="scroll"
                                                    headerColumnGroup={headerColumnGroup} alignHeader="center" align="center" showGridlines
                                                    rowGroupMode="subheader" groupRowsBy="inspcategname" rowGroupHeaderTemplate={headerTemplate}>
                                                    <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                    <Column field="name" />
                                                    <Column field="specification" />
                                                    <Column field="standard" />
                                                    <Column field="aktual_standard" />
                                                    <Column field="judgment" align="center" body={judgementTemplate}/>
                                                </DataTable>
                                            </div>
                                        )}
                                        {isMFI == 'MFI' && (
                                            <div className="card mt-2">
                                                <TabView  >
                                                        {Object.entries(groupedData).map(([department, departmentData], index) => {
                                                            return  (
                                                                <TabPanel key={index} header={department} >
                                                                    <label htmlFor='is-active'>List Report</label>
                                                                    <div>
                                                                        <DataTable key={index} value={departmentData} size="small" responsiveLayout="scroll" showGridlines
                                                                            headerColumnGroup={headerColumnGroup} align="center"
                                                                            rowGroupMode="subheader" groupRowsBy="inspcategcode" rowGroupHeaderTemplate={headerTemplate}>
                                                                            {/* <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                                            <Column field="name" body={(data, options) => `${data.inspcategname} ${data.name}`}/>
                                                                            <Column field="specification"/>
                                                                            <Column field="aktual_standard"/>
                                                                            <Column field="judgment" body={judgementTemplate}/> */}
                                                                            <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                                            <Column field="name" />
                                                                            <Column field="specification" />
                                                                            <Column field="standard" />
                                                                            <Column field="aktual_standard" />
                                                                            <Column field="judgment" align="center" body={judgementTemplate}/>
                                                                        </DataTable>
                                                                    </div>
                                                                </TabPanel>
                                                            )
                                                        })}
                                                    </TabView>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> List Gambar</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor='is-active'>List Printing Check</label>
                                        <div>
                                            <DataTable value={comparation} size="small" responsiveLayout="scroll"
                                                headerColumnGroup={headerColumnGroupPictureComp} alignHeader="center" align="center" showGridlines>
                                                <Column field="part_name" align="center"/>
                                                {/* <Column field="old_image" align="center" body={imageOldImageTemplate}/> */}
                                                <Column field="new_image" align="center" body={imageNewImageTemplate}/>
                                                <Column field="remarks" align="center"/>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                    <div className='form-group'>
                                        <label htmlFor='is-active'>List Spesifikasi</label>
                                        <div>
                                            <DataTable value={specification} size="small" responsiveLayout="scroll"
                                                headerColumnGroup={headerColumnGroupPictureSpec} alignHeader="center" align="center" showGridlines>
                                                <Column field="part_name" align="center" rowSpan={2} />
                                                <Column field="old_image" align="center" body={imageOldImageTemplateSpec}/>
                                                <Column field="new_image" align="center" body={imageNewImageTemplateSpec}/>
                                                <Column field="part_sub_name" rowSpan={2} align="center"/>
                                                <Column field="remarks" rowSpan={2} align="center"/>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isCompany == 'MI' && (
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> File SOP</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <iframe width={`100%`} height={`1000px`} src={iqcData.file_url}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'><i className='fa fa-tools' /> QI & QC</div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label htmlFor='is-active'>Quality Issue</label>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '50px'}} rowSpan={2}>Quality Issue</td>
                                                <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.issue} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Lot Order</td>
                                                <td><input type="text" className='form-control' value={iqcData.lotorder} onChange={(e) => setIqcData({...iqcData, lotorder: e.target.value})} style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled /></td>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Qty</td>
                                                <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_qty} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>FFR Status</td>
                                                <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.ffr_status} /></td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Rate</td>
                                                <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_rate} /></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='form-group'>
                                        <label htmlFor='is-active'>Keputusan QC</label>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}} >Sampling Code</td>
                                                <td>
                                                    <select className='form-control' style={{background: '#f8f9fa', width: '100%'}} value={iqcData.objtype} disabled >
                                                        <option value="0"></option>
                                                        <option value="73">100%</option>
                                                        <option value="72">20% atau 20 unit</option>
                                                        <option value="71" selected="">3% atau 10 unit</option>
                                                        <option value="70">6% atau 20 unit</option>
                                                        <option value="69">Non Inspeksi atau 1 unit</option>
                                                        <option value="74">1 unit dari 20 unit produksi</option>
                                                        <option value="75">1 unit dari tiap lot produksi</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty Sampling</td>
                                                <td><input type="number" className='form-control' min="0" style={{background: '#f8f9fa', width: '100%'}} value={iqcData.inspqty} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Std. Check</td>
                                                <td>
                                                    <select className='form-control' style={{background: '#f8f9fa', width: '100%'}} value={iqcData.insprule} disabled >
                                                        <option value=""></option>
                                                        <option value="N">No Check</option>
                                                        <option value="S">Sampling</option>
                                                        <option value="Y">100% Check</option>
                                                        <option value="R">Rework</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Status</td>
                                                <td>
                                                    <select className='form-control' style={{background: '#f8f9fa', width: '100%'}} value={iqcData.inspstatus} disabled >
                                                        <option value="0"></option>
                                                        <option value="1">Draft</option>
                                                        <option value="2">Launch</option>
                                                        <option value="3">Hold</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='form-group'>
                                        <label htmlFor='is-active'>Transfer</label>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Asal</td>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Tujuan</td>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty</td>
                                                <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Keterangan</td>
                                            </tr>
                                            { iqcData.insprule != "" && (
                                                <>
                                                    {(iqcData.transfer || []).map((transfer,i) => <tr>
                                                        <td style={{width: '25%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.whfrom} /></td>
                                                        <td style={{width: '25%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.whdest} /></td>
                                                        <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.qty} /></td>
                                                        <td style={{width: '30%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.description} /></td>
                                                    </tr>) }
                                                </>
                                            )}
                                        </table>
                                    </div>
                                    <div className='form-group mt-4'>
                                        <div className='d-flex float-right'>
                                            <button type='button' className='btn btn-outline-dark' style={{ width: '100%', marginRight: 20, whiteSpace: 'nowrap' }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog header={`Approval Comments`} visible={show} resizable={false} onHide={() => setShow(false)} footer={renderFooter()} style={{width: '500px'}}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <InputTextarea className='form-control' placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} rows={4} />
                                </div>
                            </div>
                        </div>
                    </Dialog>              
                </div>

            </section>
        </div>
    );
}

export default IncomingDetailScreen;