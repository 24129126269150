import React, { Component, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { getItemByPo, getModelAll, getPoAll, getPoByModel } from '../../Service/SapService';
import { getInspReportAll, getInspReportAllMFI } from '../../Service/InspReportService';
import { createIqc } from '../../Service/IqcService';
import { getInspFfrAll } from '../../Service/InspFfrService';
import { getQualityIssueAll } from '../../Service/QualityIssueService';
import { getInspSopAll } from '../../Service/InspSopService';
import { getMetaValueAll } from '../../Service/MetaValueService';
import PageOverlay from '../../Components/Overlay/PageOverlay';
import { TabView, TabPanel } from 'primereact/tabview';
import { getPartSAPAll } from '../../Service/SapService';
import { Button } from 'primereact/button';
import '../../../src/App.css';
const { $ } = window;

const IncomingAddScreen = () => {
    const navigate = useNavigate();
    const { pageState } = useParams();
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const [processing, setProcessing] = useState(false);
    const [ poNumber, setPoNumber ] = useState(null);
    const [poNumberMFI, setPoNumberMFI] = useState(null);
    const [umlinsncode, setUmlinsncode] = useState({
        U_M_LIN_SNC: '',
        U_M_DOC_SNC: '',
    });

    const [ poList, setPoList ] = useState([]);
    const [ itemCodes, setItemCodes ] = useState([]);
    const [ inspReport, setInspReport ] = useState([]);
    const [ whprod, setWhProd ] = useState([]);
    const [ whfg, setWhFg ] = useState([]);
    const [ whdestfg, setWhDestFg ] = useState([]);
    const [ whdestprod, setWhDestProd ] = useState([]);
    const isMFI = localStorage.getItem('selectedCompany');
    const [isDisabled, setIsDisabled] = useState(false);
    const [modelListMFI, setModelListMFI] = useState([]);

    const formattedModelListMFI = modelListMFI.map(item => ({
        ...item,
        formattedLabel: `${item.itemcode} - ${item.lotnumber} (${item.model})`
    }));

    const [ iqcData, setIqcData ] = useState({
        id_sop: null,
        inspdate: "",
        arrivaldate: "",
        title: "",
        ponumber: "",
        docsnc: "",
        linesnc: "",
        itemcode_assembly: "",
        itemname_assembly: "",
        grnumber: "",
        itemcode: "",
        itemname: "",
        itemdesc: "",
        lotorder: "",
        lotqty: 0,
        serialno: "",
        assyproc: "",
        insprule: "",
        inspqty: 0,
        objtype: 0,
        docentry: 0,
        linenum: 1,
        whfrom: "",
        whdest: "",
        is_changed: 1,
        changenote: "",
        conclusion: "",
        inspstatus: 0,
        ffr_id: null,
        quality_issue_id: null,
        po_snc : null,
        data: [],
        transfer: [
            {
                "whfrom": "",
                "whdest": "",
                "qty": 0,
                "type": 0,
                "description": "Sampling",
            },
            {
                "whfrom": "",
                "whdest": "",
                "qty": 0,
                "type": 1,
                "description": "No Check",
            },
        ]
    });

    const [ qualityIssue, setQualityIssue ] = useState({
        issue: "-",
        lot_order: "-",
        defect_qty: "-",
        ffr_status: "-",
        defect_rate: "-"
    });

    const [arrayTemp, setArrayTemp] = useState({
        poNumber: null,
        itemCode: null,
        lotOrder: null
    });

    const [partSAP, setPartSAP] = useState([]);
    const [selectedSAP, setSelectedSAP] = useState([]);

    const onSubmit = data => {
        setProcessing(true);
        const dataTemp = [];
        var flag = true;
        // for (var key in inspReport) {
        //     if(inspReport[key].judgement != ""){
        //         dataTemp.push({
        //             id_report: inspReport[key].id,
        //             // standard: inspReport[key].aktual,
        //             judgement: inspReport[key].judgement
        //         });
        //     } else {
        //         flag = false;
        //     }
        // }

        const processedInspReport = isMFI === 'MFI'
        ? inspReport.map(item => ({
            ...item,
            judgement: item.judgement || "" // Add `judgement` property if it doesn't exist
        }))
        : inspReport;
                
        // console.log('inspReport:', processedInspReport); // Debugging line
        for (const key in processedInspReport) {
            const reportItem = processedInspReport[key];
            if (reportItem.judgement !== "") {
                const standardValue = isMFI === 'MI' ? reportItem.aktual : reportItem.specification;
                dataTemp.push({
                    id_report: reportItem.id,
                    standard: standardValue,
                    judgement: reportItem.judgement
                });
            } else {
                flag = false;
            }
        }

        if(flag) {
            iqcData.data = dataTemp;
            iqcData.ponumber = poNumber;
            // iqcData.docsnc = umlinsncode.U_M_DOC_SNC;
            // iqcData.linesnc = umlinsncode.U_M_LIN_SNC;
            const response = createIqc(iqcData);
            response.then(res => {
                if (res.status == 200 || res.status == 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Save data success',
                        text: 'Data has been saved!'
                    });

                    navigate("/incoming/part-image/"+res.data.id_iqc);
                }

                setProcessing(false);
            }).catch(({response: { data } }) => {
                if(data.status == 422){
                    const obj = Object.keys(data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.errors[obj[0]][0]
                    });
                }else
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Save data error!'
                    });
                
                setProcessing(false);
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill all judgement data!'
            });
            setProcessing(false);
        }
    }

    const onGoback = () => {
        navigate(-1);
    }

    const getPoList = () => {
        getPoAll().then(res => {
            setPoList(res.data);
        });
    }

    const getModelListMFI = () =>{
        getModelAll().then(res => {
            setModelListMFI(res.data);
        })
    }

    const getItemCodeByPo = (ponumber) => {
        getItemByPo(ponumber).then(res => {
            setItemCodes(res.data);
        });
        reset();
    }

    const getGrPoMFIByModel = (ponumber, lotnumber) => {
        getPoByModel(ponumber, lotnumber).then(res => {
            setPoNumberMFI(res.data);
        })
    }

    const getOptions = () => {
        getMetaValueAll({'filter':'meta_function:whprod'}).then(res => {
            setWhProd(res.data.data);
        });

        const promise1 = getMetaValueAll({'filter':'meta_function:whprod,is_active:1'});
        const promise2 = getMetaValueAll({'filter':'meta_function:whfg,is_active:1'});

        Promise.all([promise1, promise2]).then(function(values) {
            setWhProd(values[0].data.data);
            setWhFg(values[1].data.data);
        });
    }

    const setWhProdVar = (e) => {
        if(e == "WH-JTSTG") {
            getMetaValueAll({'filter':'meta_function:whdestfg', 'search':e.substring(0, 5)}).then(res => {
                setWhDestFg(res.data.data);
            });

            getMetaValueAll({'filter':'meta_function:whdestprod', 'search':e.substring(0, 5)}).then(res => {
                setWhDestProd(res.data.data);
            });

            setIqcData({...iqcData, whfrom: e});
        } else {
            // setIqcData({...iqcData, whfrom: e, transfer: []});
            getMetaValueAll({'filter':'meta_function:whfg', 'search':e.substring(0, 5)}).then(res => {
                setWhDestFg(res.data.data);
            });
            getMetaValueAll({'filter':'meta_function:whprod', 'search':e.substring(0, 5)}).then(res => {
                setWhDestProd(res.data.data);
            });

            setIqcData({...iqcData, whfrom: e});
        }
    }

    const onChangeInspRule = (e) => {
        const lotQty = iqcData.lotqty;
        if (isMFI == 'MI') {
            if((e == "N" && iqcData.whfrom == "WH-JTSTG") || (e == "N" && iqcData.whfrom == "WH-TGPRD")) {
                setIqcData({...iqcData, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if(e == "S" && iqcData.whfrom == "WH-JTSTG") {
                setIqcData({...iqcData, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.inspqty,
                            "type": 0,
                            "description": "Sampling",
                        },
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty - iqcData.inspqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if (e == "S" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.inspqty,
                            "type": 0,
                            "description": "Sampling",
                        },
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty - iqcData.inspqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } 
            else if((e == "Y" || e == "R") && iqcData.whfrom == "WH-JTSTG") {
                setIsDisabled(true);
                setIqcData({...iqcData, 
                    inspqty: lotQty,
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 0,
                            "description": "Full Check",
                        }
                    ]
                });
            }
            else if((e == "Y" || e == "R") && iqcData.whfrom == "WH-TGPRD") {
                setIsDisabled(true);
                setIqcData({...iqcData, 
                    inspqty: lotQty,
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 0,
                            "description": "Full Check",
                        }
                    ]
                });
            } 
            else {
                setIqcData({...iqcData, insprule: e});
            }
        } else {
            if(e == "N" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if(e == "S" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.inspqty,
                            "type": 0,
                            "description": "Sampling",
                        },
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty - iqcData.inspqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if((e == "Y" || e == "R") && iqcData.whfrom == "WH-TGPRD") {
                setIsDisabled(true);
                setIqcData({...iqcData,
                    inspqty: lotQty, 
                    insprule: e, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 0,
                            "description": "Full Check",
                        }
                    ]
                });
            } 
            else {
                setIqcData({...iqcData, insprule: e});
            }
        }
        
    }

    const onChangeInspQty = (e) => {
        const lotQty = iqcData.lotqty;
        const numericValue = parseInt(e, 10);
        const validatedValue = isNaN(numericValue) ? "" : Math.max(0, Math.min(numericValue, lotQty));
        if (isMFI == 'MI') {
            if(iqcData.insprule == "N" && iqcData.whfrom == "WH-JTSTG") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if(iqcData.insprule == "S" && iqcData.whfrom == "WH-JTSTG" || iqcData.insprule == "S" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": validatedValue,
                            "type": 0,
                            "description": "Sampling",
                        },
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty - validatedValue,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if((iqcData.insprule == "Y" || iqcData.insprule == "R")  && iqcData.whfrom == "WH-JTSTG") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 0,
                            "description": "Full Check",
                        }
                    ]
                });
            } else {
                setIqcData({...iqcData, inspqty: e});
            }
        } else {
            if(iqcData.insprule == "N" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if(iqcData.insprule == "S" && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": validatedValue,
                            // "qty": e,
                            "type": 0,
                            "description": "Sampling",
                        },
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty - validatedValue,
                            // "qty": iqcData.lotqty - e,
                            "type": 1,
                            "description": "No Check",
                        },
                    ]
                });
            } else if((iqcData.insprule == "Y" || iqcData.insprule == "R")  && iqcData.whfrom == "WH-TGPRD") {
                setIqcData({...iqcData, 
                    inspqty: validatedValue, 
                    transfer: [
                        {
                            "whdest": "",
                            "qty": iqcData.lotqty,
                            "type": 0,
                            "description": "Full Check",
                        }
                    ]
                });
            } else {
                setIqcData({...iqcData, inspqty: e});
            }
        }   
    }

    const getDataByItemCode = (itemcode, assy, lotOrder) => {
        const isMFI = localStorage.getItem('selectedCompany');
        if(poNumber != null){
            const promise1 = getItemByPo(poNumber, itemcode, lotOrder);
            const promise2 = getInspFfrAll({'filter':'itemcode:'+itemcode+',is_active:true'});
            const promise3 = getInspSopAll({'filter':'itemcode:'+itemcode+',is_active:true'});
            const promise4 = getQualityIssueAll({'filter':'itemcode:'+itemcode});
            Promise.all([promise1, promise2, promise3, promise4]).then(function(values) {
                setIqcData({...iqcData,
                    title: "Keberterimaan Produk",
                    grnumber: isMFI == 'MI' ? poNumber : poNumberMFI,
                    arrivaldate: values[0].data[0].CreateDate != undefined ? values[0].data[0].CreateDate : "",
                    // arrivaldate: values[0].data[0].CreateDate != undefined ? new Date(values[0].data[0].CreateDate) : "",
                    itemcode: values[0].data[0].itemcode,
                    itemname: values[0].data[0].model,
                    itemdesc: values[0].data[0].Dscription,
                    lotorder: values[0].data[0].LotNumber,
                    lotqty: values[0].data[0].GR_qty != undefined ? values[0].data[0].GR_qty : "",
                    objtype: values[0].data[0].ObjType,
                    docentry: values[0].data[0].DocEntry ? values[0].data[0].DocEntry : 0,
                    linenum: values[0].data[0].LineNum,
                    whfrom: values[0].data[0].WhsCode,
                    ffr_id: values[1].data.data[0] != undefined ? values[1].data.data[0].id : undefined,
                    id_sop: values[2].data.data[0] != undefined ? values[2].data.data[0].id : undefined,
                    quality_issue_id: values[3].data.data[0] != undefined ? values[3].data.data[0].id : undefined,
                    assyproc: assy,
                    itemcode_assembly: isMFI == 'MI' ? values[0].data[0].itemcode : values[0].data[0].itemcode_assembly,
                    itemname_assembly: values[0].data[0].ItemName,
                    docsnc: values[0].data[0].U_M_DOC_SNC,
                    linesnc: values[0].data[0].U_M_LIN_SNC,
                    po_snc: isMFI == 'MI' ? '' : values[0].data[0].PO_SNC,
                });
                setQualityIssue({...qualityIssue, 
                    issue: values[3].data.data[0] != undefined ? "["+values[3].data.data[0].defect_type+" - "+values[3].data.data[0].defect_area+"] "+values[3].data.data[0].defect : "-",
                    lot_order: values[3].data.data[0] != undefined ? values[3].data.data[0].lotorder : "-",
                    defect_qty: values[3].data.data[0] != undefined ? values[3].data.data[0].defqty : "-",
                    ffr_status: values[1].data.data[0] != undefined ? values[1].data.data[0].ffr_rate * 100 + " %" : "-",
                    defect_rate: values[3].data.data[0] != undefined ? values[3].data.data[0].defrate : "-"
                });

                if(isMFI == 'MI') {
                    getMetaValueAll({'filter':'meta_function:whdestfg', 'search':values[0].data[0].WhsCode.substring(0, 5)}).then(res => {
                        setWhDestFg(res.data.data);
                    });
            
                    getMetaValueAll({'filter':'meta_function:whdestprod', 'search':values[0].data[0].WhsCode.substring(0, 5)}).then(res => {
                        setWhDestProd(res.data.data);
                    });
                } else {
                    getMetaValueAll({'filter':'meta_function:whprod', 'search':values[0].data[0].WhsCode.substring(0, 5)}).then(res => {
                        setWhDestFg(res.data.data);
                    });
                    getMetaValueAll({'filter':'meta_function:whprod', 'search':values[0].data[0].WhsCode.substring(0, 5)}).then(res => {
                        setWhDestProd(res.data.data);
                    });
                }
            });
        }
    }

    const reset = () => {
        setIqcData({
            id_sop: null,
            inspdate: "",
            arrivaldate: "",
            title: "",
            ponumber: "",
            grnumber: "",
            itemcode: "",
            itemname: "",
            itemdesc: "",
            lotorder: "",
            lotqty: 0,
            serialno: "",
            assyproc: "",
            insprule: "",
            inspqty: 0,
            objtype: 0,
            docentry: 0,
            linenum: 0,
            whfrom: "",
            whdest: "",
            is_changed: 1,
            changenote: "",
            conclusion: "",
            inspstatus: 0,
            ffr_id: null,
            quality_issue_id: null,
            data: []
        });
    }

    const judgementTemplate = (options) => {
        return <select className={`form-control judge_`+options.inspcategcode} onChange={(e) => {
            inspReport.filter(insp => insp.id == options.id).map((insp) => {
                insp.judgement = e.target.value;
            });

            var flag = true;
            var value = '';
            inspReport.filter(insp => insp.inspcategname == options.inspcategname).map((insp, index) => {
                if(index == 0) value = insp.judgement;
                if(insp.judgement != value) {
                    flag = false;
                    return;
                }else value = insp.judgement;
            });

            if(flag) document.getElementById('judge_'+options.inspcategcode).value = e.target.value;
        }}>
            <option value="" selected={options.judgement == "" ? true : false}>-</option>
            <option value="OK" selected={options.judgement == 'OK' ? true : false}>OK</option>
            <option value="NG" selected={options.judgement == 'NG' ? true : false}>NG</option>
        </select>;
    }
    // const aktualTemplate = (options) => {
    //     if (isMFI == 'MI') {
    //         return <input onChange={(e) => {
    //             inspReport.filter(insp => insp.id == options.id).map((insp) => {
    //                 insp.aktual = e.target.value;
    //             })}} className='form-control' defaultValue={options.aktual} />
    //     } else {
    //         return <input onChange={(e) => {
    //             inspReport.filter(insp => insp.id == options.id).map((insp) => {
    //                 insp.aktual = e.target.value;
    //             })}} className='form-control' defaultValue={options.specification} />
    //     }
    // }

    // const aktualTemplate = (rowData) => {
    //     console.log('aktualTemplate rowData:', rowData); // Debugging line
    //     console.log('aktualTemplate inspReport:', inspReport); // Debugging line
    //     if (isMFI === 'MI') {
    //         return (
    //             <input 
    //                 onChange={(e) => {
    //                     inspReport.filter(insp => insp.id === rowData.id).map((insp) => {
    //                         insp.aktual = e.target.value;
    //                     });
    //                 }} 
    //                 className="form-control" 
    //                 defaultValue={rowData.aktual} 
    //             />
    //         );
    //     } else {
    //         return (
    //             <input 
    //                 onChange={(e) => {
    //                     inspReport.filter(insp => insp.id === rowData.id).map((insp) => {
    //                         insp.specification = e.target.value;
    //                     });
    //                 }} 
    //                 className="form-control" 
    //                 defaultValue={rowData.specification} 
    //             />
    //         );
    //     }
    // };

    const aktualTemplate = (rowData) => {
    
        const handleInputChange = (e) => {
            const newValue = e.target.value;
            const inspItem = inspReport.find(insp => insp.id === rowData.id);
    
            if (inspItem) {
                if (isMFI === 'MI') {
                    inspItem.aktual = newValue;
                } else {
                    inspItem.specification = newValue;
                }
            }
        };
    
        return (
            <input 
                onChange={handleInputChange}
                className="form-control"
                defaultValue={isMFI === 'MI' ? rowData.aktual : rowData.specification}
            />
        );
    };
    const getInspReportByItem = async (poNumber, itemcode, assy, lotOrder) => {
        // const getInspReportByItem = async (itemcode) => {
            if (isMFI == 'MI') {
                getInspReportAll({'filter':`code:${itemcode},type:1,is_active:true,assytype:${assy}`,
                'order':'inspcategname', 'perpage': 1000}).then(res => {
                    // getInspReportAll({'filter':'code:'+itemcode+',type:1,is_active:true','order':'inspcategname', 'perpage': 1000}).then(res => {
                        res.data.data.map((value) => {
                            value.aktual = value.specification;
                            value.judgement = "";
                        });
                if (res.data.data.length > 0) {
                    setInspReport(res.data.data);
                    getDataByItemCode(itemcode, assy);
                    setIqcData({...iqcData, itemcode: itemcode, assyproc: assy});
                } else {
                    setIqcData({...iqcData, itemcode: "", arrivaldate: "", lotorder: "", lotqty: "", whfrom:"", assyproc: ""});
                    setPoNumber("");
                    setItemCodes([]);
                    setInspReport([]);
                    setQualityIssue({
                        issue: "-",
                        lot_order: "-",
                        defect_qty: "-",
                        ffr_status: "-",
                        defect_rate: "-"
                    });
                    Swal.fire({
                        icon: 'error',
                        title: 'Data not found',
                        text: 'Data report not found for this item!'
                    });
                }
            });

        } else {
                try {
                    const partResponse = await getPartSAPAll({'filter': itemcode});
                    const partValues = partResponse.data.map(item => item.value).join();
                    setPartSAP(partValues);
                    // const reportResponse = await getInspReportAllMFI({'filter':`code:${itemcode},type:1,is_active:true`, 'partcode':`${partValues}`});
                    const reportResponse = await getInspReportAllMFI({'filter':`code:${itemcode},type:1,is_active:true,assytype:${assy}`, 'partcode':`${partValues}`,
                    'order':'inspcategname', 'perpage': 1000});
                    let responseData = reportResponse.data.data;

                    if (responseData.length > 0) {
                        setInspReport(responseData);
                        setIqcData({...iqcData, itemcode: itemcode, assyproc: assy});
                        getDataByItemCode(itemcode, assy, lotOrder);
                    } else {
                        setIqcData({...iqcData, itemcode: "", arrivaldate: "", lotorder: "", lotqty: "", whfrom:"", assyproc: ""});
                        setArrayTemp({...arrayTemp, formattedLabel: ""});
                        setPoNumber("");
                        setItemCodes([]);
                        setInspReport([]);
                        setQualityIssue({
                            issue: "-",
                            lot_order: "-",
                            defect_qty: "-",
                            ffr_status: "-",
                            defect_rate: "-"
                        });
                        setPoNumberMFI("");
                        Swal.fire({
                            icon: 'error',
                            title: 'Data not found',
                            text: 'Data report not found for this item!'
                        });
                    }
                } catch (error) {
                    console.error('Error in fetching data:', error);
                }
            }
        }

    const dataSelect = (data) => {
        return data.judgement
    }

    const headerTemplate = (data) => {
        return (
            <div style={{display: 'flex', background: '#f8f9fa'}}>
                <h6 style={{marginTop: '8px', marginLeft: '10px'}}>{data.inspcategname}</h6>
                {isMFI == 'MI' && (
                    <select  className='form-control' id={`judge_`+data.inspcategcode} onChange={(e) => {
                        inspReport.filter(insp => (insp.inspcategname == data.inspcategname)).map((insp) => {
                            insp.judgement = e.target.value;
                        });
                        var judges = document.getElementsByClassName('judge_'+data.inspcategcode);
                        for (var i = 0; i < judges.length; ++i) {
                            judges[i].value = e.target.value;
                        }
                    }} style={{ width: '1vw', marginLeft: 'auto', minWidth: '78px' }}>
                        <option value="">-</option>
                        <option value="OK">OK</option>
                        <option value="NG">NG</option>
                    </select>
                )}

                {isMFI == 'MFI' && (
                    <select value={dataSelect(data)} className='form-control' id={`judge_`+data.inspcategcode} onChange={(e) => {
                        inspReport.filter(insp => (insp.inspcategname == data.inspcategname) && (insp.partcode == data.partcode)).map((insp) => {
                            insp.judgement = e.target.value;
                        });
                        var judges = document.getElementsByClassName('judge_'+data.inspcategcode);
                        for (var i = 0; i < judges.length; ++i) {
                            judges[i].value = e.target.value;
                        }
                    }} style={{ width: '1vw', marginLeft: 'auto', minWidth: '78px' }}>
                        <option value="">-</option>
                        <option value="OK">OK</option>
                        <option value="NG">NG</option>
                    </select>
                )}
            </div>
        );
    }

    const [tabsData, setTabsData] = useState([
        {
            id: 1, 
            header: 'BURNER 120', 
            content: 'Content 1'
        },
        {
            id: 2, 
            header: 'OUTTER RING 120', 
            content: 'Content 2'
        },
    ])

    const [activeIndex, setActiveIndex] = useState(0);
    const groupedData = groupDataByPartName(inspReport);
    function groupDataByPartName(inspReport) {
        const groupedData = {};
        inspReport.forEach(item => {
            if (item.partcode != null && item.partcode != '') {
                const categname = item.partcode;
                if (!groupedData[categname]) {
                    groupedData[categname] = [];
                }
                groupedData[categname].push(item);
            }
        })

        return groupedData;
    }

    const groupDataByCategory = (inspReport) => {
        return Object.values(inspReport).flat().reduce((acc, item) => {
            const key = item.inspcategname;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    };

    let headerColumnGroup = <ColumnGroup>
        <Row>
            <Column field="no" header="No" alignHeader="center" style={{ width: '5%' }} rowSpan={2}/>
            <Column field="name" header="Item Pemeriksaan" alignHeader="center" rowSpan={2}/>
            <Column field="specification" header="Spesifikasi" alignHeader="center" rowSpan={2}/>
            {/* <Column field="standard" header="Standard" alignHeader="center" rowSpan={2}/> */}
            <Column header="Hasil" alignHeader="center" colSpan={2} />
        </Row>
        <Row>
            <Column header="Aktual" align="center" alignHeader="center" field="standard" style={{ width: '25%' }}/>
            <Column header="Judge" align="center" alignHeader="center" field="judgement" style={{ width: '1vw', minWidth: '95px' }}/>
        </Row>
    </ColumnGroup>;
     
    useEffect(() => {
        getPartSAPAll();
        getPoList();
        getOptions();
        getModelListMFI();
    }, []);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Manage IQC</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">IQC</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <PageOverlay display={processing} />
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title'><i className='fa fa-tools' /> {pageState && pageState.charAt(0).toUpperCase() + pageState.slice(1)} Add IQC</div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    {isMFI == 'MI' && (
                                        <>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>GR - PO</label>
                                                <div>
                                                    <Dropdown value={poNumber} options={poList} 
                                                        // onChange={(e) => {setPoNumber(e.target.value); getItemCodeByPo(e.target.value);}}
                                                        onChange={e => { 
                                                            setArrayTemp({...arrayTemp, poNumber: e.target.value}); setPoNumber(e.target.value); getItemCodeByPo(e.target.value); 
                                                            setUmlinsncode({...umlinsncode,
                                                            U_M_DOC_SNC: poList.find(val => val.ponumber == e.target.value).U_M_DOC_SNC,
                                                            U_M_LIN_SNC: poList.find(val => val.ponumber == e.target.value).U_M_LIN_SNC,
                                                            // U_M_LIN_SNC: poList.find(
                                                            //     (val) => {
                                                            //         if(val.ponumber ==  e.target.value) return val
                                                            //     }).U_M_LIN_SNC
                                                        });}}
                                                        // onChange={e => {setInspReport({...inspReport, id_category: e.target.value}); getInspItem(e.target.value, inspReport.id_insp_categ);}} 
                                                        optionLabel="ponumber" optionValue="ponumber" filter filterBy="ponumber" 
                                                        style={{width: '100%', height: '37px'}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Model</label>
                                                <select className='form-control' 
                                                    // onChange={e => {getInspReportByItem(e.target.value)}}
                                                    // value={iqcData.itemcode}
                                                    onChange={e =>
                                                        { setArrayTemp({...arrayTemp, itemCode: e.target.value.split('|')[0],
                                                                        lotOrder: e.target.value.split('|')[1]})
                                                        ;}
                                                        }
                                                    // onChange={e => {setInspReport({...inspReport, id_category: e.target.value}); getInspItem(e.target.value, inspReport.id_insp_categ);}} 
                                                    >
                                                    {/* <option value="" selected={iqcData.itemcode == ""  && iqcData.lotorder == "" ? true : false}></option>
                                                    {(itemCodes || []).map((e, i) => <option key={'key_itemcode_' + i} value={`${e.itemcode}|${e.U_M_LOT_ORDR}`} selected={iqcData.itemcode == e.itemcode  && iqcData.lotorder == e.U_M_LOT_ORDR? true : false}>{`${e.itemcode} - ${e.U_M_LOT_ORDR} (${e.model})`}</option>)} */}
                                                    <option value="" selected={iqcData.itemcode == "" && iqcData.lotorder == "" ? true : false}></option>
                                                    {(itemCodes || []).map((e, i) => {
                                                        // Define a default value for U_M_LOT_ORDR if it is null or empty
                                                        const lotOrder = e.U_M_LOT_ORDR && e.U_M_LOT_ORDR.trim() !== "" ? e.U_M_LOT_ORDR : " ";
                                                        return (
                                                            <option
                                                                key={'key_itemcode_' + i}
                                                                value={`${e.itemcode}|${lotOrder}`}
                                                                selected={(iqcData.itemcode == e.itemcode && iqcData.lotorder == lotOrder) ? true : false}
                                                            >
                                                                {`${e.itemcode} - ${lotOrder} (${e.model})`}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        </>
                                    )}

                                    {isMFI == 'MFI' && (
                                        <>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>GR - PO MFI</label>
                                                <div>
                                                    <input
                                                        type='text'
                                                        value={poNumberMFI && Array.isArray(poNumberMFI) ? poNumberMFI.map(item => item.grpo).join(', ') : 'Select Model First'}
                                                        onChange={e => {
                                                            // If you need to update the state or perform other actions,
                                                            // you can still do that here if needed.
                                                            setArrayTemp({ ...arrayTemp, poNumber: e.target.value });
                                                            setPoNumber(e.target.value);
                                                            getItemCodeByPo(e.target.value);
                                                            setUmlinsncode({
                                                                ...umlinsncode,
                                                                U_M_DOC_SNC: poList.find(val => val.grpo === e.target.value)?.U_M_DOC_SNC,
                                                                U_M_LIN_SNC: poList.find(val => val.grpo === e.target.value)?.U_M_LIN_SNC,
                                                            });
                                                        }}
                                                        disabled // Disables the input field
                                                        style={{ width: '100%', height: '37px' , padding: '10px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Model</label>
                                                <Dropdown 
                                                    value={arrayTemp.formattedLabel} 
                                                    options={formattedModelListMFI} 
                                                    onChange={e => { 
                                                        const selectedOption = formattedModelListMFI.find(item => item.formattedLabel === e.value);
                                                        if (selectedOption) {
                                                            setArrayTemp({
                                                                ...arrayTemp, 
                                                                itemCode: selectedOption.itemcode, 
                                                                poNumber: selectedOption.ponumber, 
                                                                lotOrder: selectedOption.lotnumber,
                                                                formattedLabel: selectedOption.formattedLabel
                                                            });
                                                            setPoNumber(selectedOption.ponumber);
                                                            // getItemCodeByPo(selectedOption.ponumber);
                                                            getGrPoMFIByModel(selectedOption.itemcode, selectedOption.lotnumber); 
                                                            setUmlinsncode({
                                                                ...umlinsncode,
                                                                U_M_DOC_SNC: poList.find(val => val.ponumber === selectedOption.ponumber)?.U_M_DOC_SNC || '',
                                                                U_M_LIN_SNC: poList.find(val => val.ponumber === selectedOption.ponumber)?.U_M_LIN_SNC || ''
                                                            });
                                                            setIqcData({...iqcData, lotorder:"", assyproc: "", arrivaldate: "", lotqty: "", whfrom: "", po_snc: ""});
                                                        }
                                                    }}
                                                    optionLabel="formattedLabel" // Use the formatted label
                                                    optionValue="formattedLabel" // Set the value option to itemcode
                                                    filter 
                                                    filterBy="formattedLabel" 
                                                    style={{width: '100%', height: '37px'}} 
                                                />
                                            </div>
                                        </div>
                                        </>
                                    )}
                                    
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Serial</label>
                                            <input id="lot-order" className='form-control' value={iqcData.serialno} onChange={(e) => setIqcData({...iqcData, serialno: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Assy. Process</label>
                                            {isMFI == 'MI' && (
                                                <select className='form-control' 
                                                // onChange={e => {setIqcData({...iqcData, assyproc: e.target.value});
                                                // }} >
                                            
                                                    onChange={e => {
                                                        getInspReportByItem(arrayTemp.poNumber, arrayTemp.itemCode, e.target.value);
                                                        setIqcData({...iqcData, assyproc: e.target.value});
                                                    }} >

                                                <option value="" selected={iqcData.assyproc == "" ? true : false}></option>
                                                <option value="CBU" selected={iqcData.assyproc == 'CBU' ? true : false}>CBU</option>
                                                {/* <option value="CKD" selected={iqcData.assyproc == 'CKD' ? true : false}>CKD</option>
                                                <option value="SKD" selected={iqcData.assyproc == 'SKD' ? true : false}>SKD</option> */}
                                                </select>
                                            )}
                                            {
                                                isMFI == 'MFI' && (
                                                    <select className='form-control' 
                                                // onChange={e => {setIqcData({...iqcData, assyproc: e.target.value});
                                                // }} >
                                            
                                                onChange={e => {
                                                    getInspReportByItem(arrayTemp.poNumber, arrayTemp.itemCode, e.target.value, arrayTemp.lotOrder);
                                                    setIqcData({...iqcData, assyproc: e.target.value});
                                                    }} >

                                                <option value="" selected={iqcData.assyproc == "" ? true : false}></option>
                                                {/* <option value="CBU" selected={iqcData.assyproc == 'CBU' ? true : false}>CBU</option> */}
                                                <option value="CKD" selected={iqcData.assyproc == 'CKD' ? true : false}>CKD</option>
                                                <option value="SKD" selected={iqcData.assyproc == 'SKD' ? true : false}>SKD</option>
                                            </select>
                                            )}  
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-tiba'>Tanggal Tiba</label>
                                            <input type="date" id="tanggal-tiba" className='form-control' value={iqcData.arrivaldate} onChange={(e) => setIqcData({...iqcData, arrivaldate: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='tanggal-uji'>Tanggal Uji</label>
                                            <input type="date" id="tanggal-uji" className='form-control' value={iqcData.inspdate} onChange={(e) => setIqcData({...iqcData, inspdate: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Lot Order</label>
                                            <input id="lot-order" className='form-control' value={iqcData.lotorder} onChange={(e) => setIqcData({...iqcData, lotorder: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Lot Qty</label>
                                            <input type="number" min="0" id="lot-qty" className='form-control' value={iqcData.lotqty} onChange={(e) => setIqcData({...iqcData, lotqty: e.target.value})} readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH Prod</label>
                                            <select className='form-control' onChange={e => setWhProdVar(e.target.value)}>
                                                <option value="" selected={iqcData.whfrom == "" ? true : false}></option>
                                                {(whprod || []).map((e, i) => <option key={'key_wh_prod' + i} value={e.meta_value} selected={iqcData.whfrom == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                {/* <option value="WH-KWPRD" selected={iqcData.whfrom == 'WH-KWPRD' ? true : false}>[WH-KWPRD] Warehouse Karawaci</option>
                                                <option value="WH-TGPRD" selected={iqcData.whfrom == 'WH-TGPRD' ? true : false}>[WH-TGPRD] Warehouse Tangerang</option>
                                                <option value="WH-JTPRD" selected={iqcData.whfrom == 'WH-JTPRD' ? true : false}>[WH-JTPRD] Warehouse Jatake</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>WH FG</label>
                                            <select className='form-control' onChange={e => setIqcData({...iqcData, whdest: e.target.value})} >
                                                <option value="" selected={iqcData.whdest == "" ? true : false}></option>
                                                {(whfg || []).map((e, i) => <option key={'key_wh_fg' + i} value={e.meta_value} selected={iqcData.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                {/* <option value="WH-KWFGA" selected={iqcData.whdest == 'WH-KWFGA' ? true : false}>[WH-KWFGA] Warehouse Finished Goods Grade A Karawaci</option>
                                                <option value="WH-TGFGA" selected={iqcData.whdest == 'WH-TGFGA' ? true : false}>[WH-TGFGA] Warehouse Finished Goods Grade A Tangerang</option>
                                                <option value="WH-JTFGA" selected={iqcData.whdest == 'WH-JTFGA' ? true : false}>[WH-JTFGA] Warehouse Finished Goods Grade A Jatake</option>
                                                <option value="WH-JTP04" selected={iqcData.whdest == 'WH-JTP04' ? true : false}>[WH-JTP04] Warehouse Perlengkapan Penunjang Produksi Jatake</option>
                                                <option value="WH-JTRWK" selected={iqcData.whdest == 'WH-JTRWK' ? true : false}>[WH-JTRWK] Warehouse Rework Jatake</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {isMFI == 'MFI' && (
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor='lot-qty'>PO SNC</label>
                                                <input type="text" id="po-snc" className='form-control' value={iqcData.po_snc} onChange={(e) => setIqcData({...iqcData, lotqty: e.target.value})} readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <div className="form-group">
                                            <label>Perubahan</label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 0})} checked={iqcData.is_changed === 0}/> Ada
                                            </label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 1})} checked={iqcData.is_changed === 1}/> Tidak
                                            </label>
                                            <label class="form-control">
                                                <input type="radio" onChange={(e) => setIqcData({...iqcData, is_changed: 2})} checked={iqcData.is_changed === 2}/> Baru
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-order'>Informasi</label>
                                            <textarea id="lot-order" className='form-control' rows={3} value={iqcData.changenote} onChange={(e) => setIqcData({...iqcData, changenote: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='form-group'>
                                            <label htmlFor='lot-qty'>Kesimpulan</label>
                                            <textarea id="lot-qty" className='form-control' rows={3} value={iqcData.conclusion} onChange={(e) => setIqcData({...iqcData, conclusion: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>List Report</label>
                                            {isMFI == 'MI' && (
                                                <div>
                                                    <DataTable value={inspReport} size="small" responsiveLayout="scroll" showGridlines
                                                        headerColumnGroup={headerColumnGroup} align="center"
                                                        rowGroupMode="subheader" groupRowsBy="inspcategname" rowGroupHeaderTemplate={headerTemplate}>
                                                        <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                        <Column field="name"/>
                                                        <Column field="specification"/>
                                                        <Column field="standard" body={aktualTemplate}/>
                                                        <Column field="judgment" body={judgementTemplate}/>
                                                    </DataTable>
                                                </div>

                                            )}
                                            {isMFI == 'MFI' && (
                                                <div className="card mt-2">
                                                    <TabView  >
                                                        {Object.entries(groupedData).map(([department, departmentData], index) => {
                                                            return  (
                                                                // <TabPanel key={department + index} header={departmentData.length > 0 ? departmentData[0].partname : department}>
                                                                <TabPanel key={index} header={departmentData.length > 0 ? departmentData[0].partname : department} >
                                                                    <label htmlFor='is-active'>List Report</label>
                                                                    <div>
                                                                        <DataTable key={index} value={departmentData} size="small" responsiveLayout="scroll" showGridlines
                                                                            headerColumnGroup={headerColumnGroup} align="center"
                                                                            rowGroupMode="subheader" groupRowsBy="inspcategcode" rowGroupHeaderTemplate={headerTemplate}>
                                                                            <Column field="no" body={(data, options) => options.rowIndex + 1} align="center"/>
                                                                            <Column field="name" body={(data, options) => `${data.inspcategname} ${data.name}`}/>
                                                                            <Column field="specification"/>
                                                                            {/* <Column field="standard" body={aktualTemplate}/> */}
                                                                            <Column field="standard" body={(rowData) => aktualTemplate(rowData)}/>
                                                                            <Column field="judgment" body={judgementTemplate}/>
                                                                        </DataTable>
                                                                    </div>
                                                                </TabPanel>
                                                            )
                                                        })}
                                                    </TabView>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Quality Issue</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '50px'}} rowSpan={2}>Quality Issue</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.issue} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Lot Order</td>
                                                    <td><input type="text" className='form-control' value={qualityIssue.lot_order} style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled /></td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Qty</td>
                                                    <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_qty} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>FFR Status</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.ffr_status} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Defect Rate</td>
                                                    <td colSpan={4}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={qualityIssue.defect_rate} /></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-5 mt-2'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Keputusan QC</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}} >Sampling Code</td>
                                                    <td>
                                                        <select className='form-control' style={{width: '100%'}} value={iqcData.objtype} onChange={(e) => setIqcData({...iqcData, objtype: e.target.value})} >
                                                            <option value=""></option>
                                                            <option value="73">100%</option>
                                                            <option value="72">20% atau 20 unit</option>
                                                            <option value="71" selected="">3% atau 10 unit</option>
                                                            <option value="70">6% atau 20 unit</option>
                                                            <option value="69">Non Inspeksi atau 1 unit</option>
                                                            <option value="74">1 unit dari 20 unit produksi</option>
                                                            <option value="75">1 unit dari tiap lot produksi</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Std. Check</td>
                                                    <td>
                                                        {isMFI == 'MI' && (
                                                            <select className='form-control' style={{width: '100%'}} value={iqcData.insprule} onChange={(e) => onChangeInspRule(e.target.value)} >
                                                                <option value=""></option>
                                                                <option value="N">No Check</option>
                                                                <option value="S">Sampling</option>
                                                                <option value="Y">100% Check</option>
                                                                <option value="R">Rework</option>
                                                            </select>
                                                        )}
                                                        {
                                                            isMFI == 'MFI' && (
                                                                <select className='form-control' style={{width: '100%'}} value={iqcData.insprule} onChange={(e) => onChangeInspRule(e.target.value)} >
                                                                    <option value=""></option>
                                                                    <option value="S">Sampling</option>
                                                                    <option value="Y">100% Check</option>
                                                                    <option value="R">Rework</option>
                                                                </select>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty Sampling</td>
                                                    <td><input type="number" className='form-control' min="0" style={{width: '100%'}} value={iqcData.inspqty} 
                                                        onChange={(e) => onChangeInspQty(e.target.value)} disabled={isDisabled} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Status</td>
                                                    <td>
                                                        <select className='form-control' style={{width: '100%'}} value={iqcData.inspstatus} onChange={(e) => setIqcData({...iqcData, inspstatus: e.target.value})} >
                                                            <option value=""></option>
                                                            <option value="1">Draft</option>
                                                            <option value="2">Launch</option>
                                                            <option value="3">Hold</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='form-group'>
                                            <label htmlFor='is-active'>Transfer</label>
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Asal</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>WH Tujuan</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Qty</td>
                                                    <td style={{background: '#f8f9fa', textAlign: 'center', padding: '18px'}}>Keterangan</td>
                                                </tr>
                                                { iqcData.insprule != "" && (
                                                    <>
                                                        {(iqcData.transfer || []).map((transfer,i) => <tr>
                                                            <td style={{width: '25%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={iqcData.whfrom} /></td>
                                                            <td style={{width: '25%'}}>
                                                                <select className='form-control' onChange={e => transfer.whdest = e.target.value } >
                                                                    <option value="" selected={transfer.whdest == "" ? true : false}></option>
                                                                    { transfer.type == 1 && (
                                                                        <>
                                                                            {(whdestfg || []).map((e, i) => <option key={'key_wh_dest' + i} value={e.meta_value} selected={transfer.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                                        </>
                                                                    )}
                                                                    { transfer.type == 0 && (
                                                                        <>
                                                                            {(whdestprod || []).map((e, i) => <option key={'key_wh_dest' + i} value={e.meta_value} selected={transfer.whdest == e.meta_value ? true : false}>{e.meta_description}</option>)}
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td style={{width: '20%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.qty} /></td>
                                                            <td style={{width: '30%'}}><input type="text" className='form-control' style={{width: '100%', textAlign: 'center', background: '#f8f9fa'}} disabled value={transfer.description} /></td>
                                                        </tr>) }
                                                    </>
                                                )}

                                                {/* {isMFI == 'MFI' && (
                                                    <>
                                                    <h1>MFI</h1>
                                                </>
                                                )} */}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div style={{ height: 1, background: '#ccc', margin: '20px 0' }} />
                                        <div className='form-group'>
                                            <div className='d-flex float-right'>
                                                <button type='button' className='btn btn-outline-dark' style={{ width: 100, marginRight: 20 }} onClick={onGoback}><i className='fa fa-reply' /> Back</button>
                                                <button type='submit' className='btn btn-dark' style={{ width: 150 }}><i className='fa fa-save' /> Save & Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer'></div>
                        </div>
                    </form>
                </div>

            </section>
        </div>
    );
}

export default IncomingAddScreen;